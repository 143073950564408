/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import MainLayout from 'components/layouts/Main'
import { navigate } from 'gatsby'
import * as React from 'react'
import { isLoggedIn } from 'services/auth'
import appContent from 'content/app.json'
import styled from 'styled-components'
import Container from 'components/common/Container'
import HeaderSVG from 'assets/icons/banner.svg'
import Spinner from 'components/common/Spinner'

const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`

const HeaderContainer = styled(Container)`
  margin-top: 28px;
  margin-bottom: 53px;
`

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const withAuth = (ImportComponent: React.FC<any>) =>
  class extends React.Component {
    state = {
      isAuthenticated: false,
    }

    componentDidMount() {
      isLoggedIn().then((result) => {
        if (result) {
          this.setState({ isAuthenticated: true })
        } else {
          navigate('/login', { replace: true })
        }
      })
    }

    render() {
      return this.state.isAuthenticated ? (
        <ImportComponent {...this.props} />
      ) : (
        <MainLayout
          backgroundColor={appContent.backgroundColor}
          backgroundImage={appContent.backgroundImage}
          content={
            <ContentContainer>
              <HeaderContainer>
                <HeaderSVG />
              </HeaderContainer>

              <LoadingContainer>
                <Spinner />
              </LoadingContainer>
            </ContentContainer>
          }
        />
      )
    }
  }

export default withAuth
