import * as React from 'react'
import styled from 'styled-components'
import { PageProps } from 'gatsby'

import MainLayout from 'components/layouts/Main'
import Container from 'components/common/Container'
import Paragraph from 'components/common/typography/Paragraph'
import Image from 'components/custom/Image'

import colors from 'lib/utils/colors'

import appContent from 'content/app.json'
import contactUsContent from 'content/contact-us.json'
import withAuth from 'lib/utils/withAuth'

const ContentContainer = styled(Container)`
  text-align: center;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
  padding: 24px 40px 0;
`

const InfoContainer = styled.div`
  margin-bottom: 4px;
`

const Label = styled.h5`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
`

const ContactLink = styled.a`
  color: white;
  text-decoration: none;
`

const ContactsContainer = styled.div`
  margin-bottom: 18px;
`

const ContactItemContainer = styled.div`
  margin-bottom: 20px;
`

const ContactName = styled.h4`
  color: ${colors.Brass};
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin-bottom: 6px;
`

type ContactItem = {
  image?: string
  name?: string
  phone?: string
}

const ContactUsPage: React.FC<PageProps> = ({ location }) => {
  const { title, content } = contactUsContent
  const { info, contacts, paragraph } = content

  return (
    <MainLayout
      backgroundColor={appContent.backgroundColor}
      backgroundImage={appContent.backgroundImage}
      showTabBar={!!appContent.tabBar}
      currentPath={location.pathname}
      pageTitle={title.text}
      content={
        <ContentContainer>
          <InfoContainer>
            <Label>{info.text}</Label>
          </InfoContainer>
          <ContactsContainer>
            {contacts.map((item: ContactItem, key: string) => (
              <ContactItemContainer key={key}>
                {item.image && (
                  <Container style={{ marginBottom: 10 }}>
                    <Image
                      imgSrc={item.image}
                      alt={item.image}
                      style={{
                        width: 82,
                        height: 82,
                      }}
                    />
                  </Container>
                )}
                {item.name && <ContactName>{item.name}</ContactName>}
                {item.phone && (
                  <Label>
                    <ContactLink href={`tel:${item.phone}`}>{item.phone}</ContactLink>
                  </Label>
                )}
              </ContactItemContainer>
            ))}
          </ContactsContainer>
          <Paragraph text={paragraph.text} />
          <ContactLink href={`mailto: ${content.contactEmail.email}`}>
            <Paragraph text={`${content.contactEmail.text} ${content.contactEmail.email}`} />
          </ContactLink>

          <ContactLink href={content.termsOfUse.linkTo} target='_blank'>
            <Paragraph text={content.termsOfUse.text} />
          </ContactLink>
        </ContentContainer>
      }
    />
  )
}

export default withAuth(ContactUsPage)
