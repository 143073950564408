import * as React from 'react'
import styled from 'styled-components'

type Props = {
  text?: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

const ParagraphContainer = styled.p`
  font-size: 13px;
  font-weight: 300;
  white-space: pre-wrap;
  line-height: 18px;
  letter-spacing: 0.03em;
`

const Paragraph: React.FC<Props> = ({ text, children, style }) => {
  return (
    <ParagraphContainer style={style}>
      {text}
      {children}
    </ParagraphContainer>
  )
}

export default Paragraph
